/**
 * @typedef {'mobile' | 'desktop'} ScreenSize
 */

/**
 * Enum for ScreenSizes values.
 * @readonly
 * @enum {ScreenSize}
 */
const ScreenSize = Object.freeze({
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
});

export default ScreenSize;
