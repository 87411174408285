import { getAuth } from 'firebase/auth';

export const getters = {
  getCurrentUserFromFirebaseAuth(state) {
    if (state.currentUser) {
      return state.currentUser;
    }
    // get the user from the firebase auth SDK
    return new Promise(resolve => {
      const auth = getAuth();
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      });
    });
  },
  userFSDocument(state) {
    return state.userFSDocument;
  },
  getUserOrganizationId(_, getters) {
    return getters.userFSDocument?.organizationId;
  },
  getEmailPreferences(_, getters) {
    return getters.userFSDocument?.shouldReceiveEmails;
  },
};
