export const getters = {
  categories(state) {
    return state.categories;
  },
  getCategoryById: state => id => {
    return state.categories.find(category => category.id === id);
  },
  popularCategories(state) {
    return state.categories.filter(category => category.isPopular);
  },
  unpopularCategories(state) {
    return state.categories.filter(category => !category.isPopular);
  },
  areCategoriesFetched(state) {
    return state.categories.length > 0;
  },
};
