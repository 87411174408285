import { ApiService } from '../../services/ApiService';
import vuetify from '@/plugins/vuetify';

const entityPrefix = 'organizations';
export const actions = {
  async fetchOne(context, id) {
    const response = await ApiService.get(`/${entityPrefix}/${id}`);

    const organization = response.data;
    context.commit('setOrganization', organization);

    if (organization?.theme?.primaryColor && organization?.theme?.secondaryColor) {
      vuetify.framework.theme.themes.light.primaryColor = organization?.theme?.primaryColor;
      vuetify.framework.theme.themes.light.secondaryColor = organization?.theme?.secondaryColor;
    }
    return response;
  },
  isAcceptingNewMemebers(_, id) {
    return ApiService.get(`/${entityPrefix}/isAcceptingNewMemebers/${id}`);
  },
};
