/**
 * @typedef {Object} Payload
 * @property {string} url The URL of the file you need to get the thumbnail of.
 * @property {('CoursesCovers' | 'AuthorsPhotos' | 'categoriesLogos')} folder The folder where the file is located.
 */

import { getDownloadURL, getStorage, ref } from 'firebase/storage';

/**
 * Get a picture of lesser size and quality to save up bandwidth and make the app load faster.
 * Each thumbnail has the same name as the uploaded cover with a suffix of _1000x1000.
 * All thumbnails are saved under the folder `payload.folder`/thumbs.
 * @param {Payload} payload the payload object containing the url and the folder name.
 * @returns {Promise<string>} a promise, which if successfully fulfilled, returns the thumbnail URL from firebase.
 **/

export default async function getThumbnail({ url, folder }) {
  const urlArray = url.split('%2F');
  let fileName = urlArray[urlArray.length - 1].split('?')[0];
  const path = `/${folder}/thumbs/${fileName}_1000x1000`;

  const storage = getStorage();
  const storageRef = ref(storage, path);
  const thumbnailUrl = await getDownloadURL(storageRef);
  return thumbnailUrl;
}
