import { ApiService } from '../../services/ApiService';

const entityPrefix = 'authors';
export const actions = {
  async fetchAll(context, hardFetch = false) {
    const isAuthorsEmpty = Object.keys(context.state.authors).length === 0;
    if (isAuthorsEmpty || hardFetch) {
      const { data } = await ApiService.get(`/${entityPrefix}`);
      context.commit('setAuthors', data);
    }
  },
};
