<template>
  <v-app>
    <v-progress-circular
      v-if="!isAppReady"
      color="brandGrey1"
      indeterminate
      :size="49"></v-progress-circular>
    <v-main v-else>
      <router-view />
      <v-snackbar
        v-model="getSnackbar.isSnackbarOpen"
        rounded="pill"
        :color="getSnackbar.color"
        :timeout="3000">
        <div>
          {{ getSnackbar.message }}
        </div>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { getAuth } from 'firebase/auth';
  import { createRgbVarsForThemes } from '@/utils';

  export default {
    name: 'App',
    data() {
      return {
        totalTimeSpentTrackingInterval: null,
        isAppReady: false,
      };
    },
    async mounted() {
      const screenSize = document.documentElement.clientWidth;
      this.$store.commit('initScreenSize', screenSize);
      // scroll up
      window.scrollTo(0, 1);
      // fire event listener to change state of authentication
      this.$store.dispatch('AuthModule/onAuthStateChanged');
      const auth = getAuth();
      auth.onAuthStateChanged(async user => {
        this.isAppReady = false;
        if (user) {
          await this.fetchUserFSDocument();
          if (this.organizationId) await this.fetchOrganization(this.organizationId);
          this.startTrackingTotalTimeSpent();
        }
        this.isAppReady = true;
        createRgbVarsForThemes(this.$vuetify.theme.themes);
      });
      this.$vuetify.breakpoint.mobileBreakpoint = 768;
    },
    computed: {
      ...mapGetters(['getSnackbar']),
      ...mapGetters({
        authenticatedUser: 'AuthModule/getCurrentUserFromFirebaseAuth',
        organizationId: 'AuthModule/getUserOrganizationId',
        organization: 'OrganizationsModule/getOrganization',
      }),
    },
    methods: {
      ...mapActions('StatsModule', ['increaseTimeSpent']),
      ...mapActions('AuthModule', ['fetchUserFSDocument']),
      ...mapActions('OrganizationsModule', { fetchOrganization: 'fetchOne' }),
      startTrackingTotalTimeSpent() {
        const TOTAL_TIME_SPENT_INTERVAL_DURATION = 60000;
        // const TOTAL_TIME_SPENT_INTERVAL_DURATION = 1000;
        this.totalTimeSpentTrackingInterval = setInterval(() => {
          this.increaseTimeSpent();
        }, TOTAL_TIME_SPENT_INTERVAL_DURATION);
      },
      endTrackingTotalTimeSpent() {
        clearInterval(this.totalTimeSpentTrackingInterval);
      },
    },
    watch: {
      authenticatedUser(newVal) {
        if (!newVal.accessToken) {
          this.endTrackingTotalTimeSpent();
        }
      },
    },
  };
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .v-application {
    font-family: 'Montserrat' !important;
  }

  .up-icon > span {
    display: flex;
    flex-direction: column !important;
  }

  .brand-gradient {
    background-image: linear-gradient(to right, var(--v-primaryColor-base), var(--v-secondaryColor-base));
  }

  .theme--light.v-application {
    color: var(--v-textColor-base) !important;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0) !important;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot > label {
    left: 10px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot {
    padding-left: 10px;
  }

  .theme--light.v-text-field > .v-input__control > .v-text-field__details {
    padding-left: 10px;
  }

  .theme--light.v-input input {
    color: var(--v-brandGrey2-base) !important;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute !important;
  }

  .mdi-arrow-left {
    background: linear-gradient(to right, var(--v-primaryColor-base) 20%, var(--v-secondaryColor-base));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* Use this class to make left-arrow-icon white (ex: author) */
  .mdi-arrow-left-author {
    color: white !important;
    background: transparent !important;
    -webkit-text-fill-color: white;
  }

  .v-snack {
    width: fit-content;
    margin-inline: auto;
  }

  .v-snack__content {
    font-size: 16px;
    padding: 14px 42px;
  }

  .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
  }

  .slide-up-enter-active,
  .slide-left-enter-active,
  .slide-right-enter-active {
    transition: all 0.3s ease;
  }

  .slide-up-enter {
    transform: translateY(100%);
    opacity: 0;
  }

  .slide-left-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-right-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  svg {
    outline: none;
  }

  .v-text-field .v-input__append-inner {
    cursor: pointer;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .fixed {
    position: fixed;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100%;
  }
</style>
<style lang="scss" scoped>
  .v-progress-circular {
    margin: auto;
  }
</style>
