const CREDENTIAL_ALREADY_IN_USE = 'auth/credential-already-in-use';
const CREDENTIAL_MISMATCH = 'auth/custom-token-mismatch';
const CREDENTIAL_TOO_OLD_LOGIN_AGAIN = 'auth/requires-recent-login';
const EMAIL_EXISTS = 'auth/email-already-in-use';
const INTERNAL_ERROR = 'auth/internal-error';
const INVALID_AUTH = 'auth/invalid-user-token';
const INVALID_EMAIL = 'auth/invalid-email';
const INVALID_PASSWORD = 'auth/wrong-password';
const QUOTA_EXCEEDED = 'auth/quota-exceeded';
const REJECTED_CREDENTIAL = 'auth/rejected-credential';
const TIMEOUT = 'auth/timeout';
const TOKEN_EXPIRED = 'auth/user-token-expired';
const TOO_MANY_ATTEMPTS_TRY_LATER = 'auth/too-many-requests';
const UNAUTHORIZED_DOMAIN = 'auth/unauthorized-continue-uri';
const USER_NOT_FOUND = 'auth/user-not-found';
const USER_DISABLED = 'auth/user-disabled';
const USER_MISMATCH = 'auth/user-mismatch';
const WEAK_PASSWORD = 'auth/weak-password';
const WEB_STORAGE_UNSUPPORTED = 'auth/web-storage-unsupported';

export default function translateFirebaseError(error) {
  let errorMessage = '';
  const errorCode = error?.code || error;
  switch (errorCode) {
    case CREDENTIAL_ALREADY_IN_USE:
      errorMessage = 'Sorry, we’re taken! This credential is already in use. Please sign in under a different name.';
      break;
    case CREDENTIAL_MISMATCH:
      errorMessage = 'The custom token is invalid or expired.';
      break;
    case CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      errorMessage = 'Whoops! Your session has expired. You need to sign in again to perform this action.';
      break;
    case EMAIL_EXISTS:
      errorMessage =
        'Sorry, we’re taken! This email is already in use. Please sign in with a different email or reset your password.';
      break;
    case INTERNAL_ERROR:
      errorMessage = 'Whoops! An internal error occurred. Please try again.';
      break;
    case INVALID_AUTH:
      errorMessage = "The user's credential is no longer valid. Please sign in again.";
      break;
    case INVALID_EMAIL:
      errorMessage = 'Uh oh! This email address is not valid.';
      break;
    case INVALID_PASSWORD:
      errorMessage = 'Uh oh! This password is incorrect. Please try again or reset your password.';
      break;
    case QUOTA_EXCEEDED:
      errorMessage = 'The authentication quota has been exceeded. Please try again later.';
      break;
    case REJECTED_CREDENTIAL:
      errorMessage = 'The credential is invalid or expired. Please sign in with a different credential.';
      break;
    case TIMEOUT:
      errorMessage = 'Whoops! The operation timed out. Please try again later.';
      break;
    case TOKEN_EXPIRED:
      errorMessage = "The user's token has expired. Please sign in again.";
      break;
    case TOO_MANY_ATTEMPTS_TRY_LATER:
      errorMessage = 'Too many requests have been made. Please try again later.';
      break;
    case UNAUTHORIZED_DOMAIN:
      errorMessage = 'The domain of the URL is not authorized to perform this action.';
      break;
    case USER_NOT_FOUND:
      errorMessage =
        'Hmm… there is no user corresponding to this email address. Please try again using a different email address.';
      break;
    case USER_DISABLED:
      errorMessage =
        'Uh oh! This user account has been disabled. Please contact your People Team / Manager for assistance.';
      break;
    case USER_MISMATCH:
      errorMessage = 'Uh oh! The provided credentials do not correspond to the previously signed in';
      break;
    case WEAK_PASSWORD:
      errorMessage =
        'This password is too weak. Please choose a stronger password - make it as funky as you can using numbers, capital- and lower-case letters!';
      break;
    case WEB_STORAGE_UNSUPPORTED:
      errorMessage =
        'Sorry! Web storage is not supported by this internet browser. Please try again using a different browser.';
      break;
    default:
      errorMessage = 'An unknown error occurred. Please try again.';
      break;
  }

  return errorMessage;
}
