import ScreenSize from '@/enums/screenSize';
import getErrorMessage from '@/utils/getErrorMessage';

export const UIModule = {
  state: () => ({
    isDrawerOpen: false,
    snackbar: {
      color: null,
      message: '',
      isSnackbarOpen: false,
      screenSize: ScreenSize.DESKTOP,
    },
  }),
  getters: {
    getScreenSize(state) {
      return state.screenSize;
    },
    isDrawerOpen(state) {
      return state.isDrawerOpen;
    },

    getSnackbar(state) {
      return state.snackbar;
    },
  },
  mutations: {
    initScreenSize(state, width) {
      state.screenSize = width < 768 ? ScreenSize.MOBILE : ScreenSize.DESKTOP;
    },
    isDrawerOpen(state, newVal) {
      state.isDrawerOpen = newVal;
    },
    setSuccessSnackbarMessage(state, message) {
      state.snackbar.message = message;
      state.snackbar.color = 'var(--v-primaryColor-base)';
      state.snackbar.isSnackbarOpen = true;
    },
    setErrorSnackbarMessage(state, error) {
      state.snackbar.message = getErrorMessage(error);
      state.snackbar.color = '#EB5353';
      state.snackbar.isSnackbarOpen = true;
    },
  },
};
