import { ApiService } from '../../services/ApiService';

const entityPrefix = 'categories';
export const actions = {
  async fetchAll({ commit, getters, rootGetters }) {
    if (!getters.areCategoriesFetched) {
      const { data } = await ApiService.get(`/${entityPrefix}/find-all-for-client`);
      const organization = await rootGetters['OrganizationsModule/getOrganization'];
      let categories = data;

      if (organization.categoriesIcons?.length > 0) {
        categories = data.map(category => {
          const icon = organization.categoriesIcons.find(el => el.categoryId == category.id);
          return {
            ...category,
            coverUrl: icon?.iconUrl || category.coverUrl,
          };
        });
      }
      commit('setCategories', categories);
    }
  },
  fetchOne(_, id) {
    return ApiService.get(`/${entityPrefix}/${id}`);
  },
};
