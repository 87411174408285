import Vue from 'vue';
import Vuex from 'vuex';
import { CategoriesModule } from './categories-module/index';
import { UIModule } from './ui-module/UIModule';
import { OrganizationsModule } from './organizations-module/index';
import { AuthModule } from './auth-module/index';
import { AuthorsModule } from './authors-module';
import { CoursesModule } from './courses-module/index';
import { StatsModule } from './stats-module/index';
import { searchModule } from './search-module/searchModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    CategoriesModule,
    OrganizationsModule,
    AuthModule,
    AuthorsModule,
    CoursesModule,
    StatsModule,
    UIModule,
    searchModule,
  },
});
