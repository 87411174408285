export default function getRandomMotivationalMessages() {
    const messages = [
        'Can you spend 10-minutes looking after your mind today?',
        'Short on time? Hit that filter button to find exercises from just 2-minutes long!',
        'Fun fact: a regular mindfulness practice increases attention span 💡',
        '“Wellness is attained little by little, and nevertheless is no little thing itself.” – Zeno',
        'Stressed? Anxious? Tired? We’ve got something for that. 👇',
        "Looking for some zen? You've come to the right place...",
        'Busy day? Let us help you find a moment of calm. 🧘‍♀️',
        "You're back! Well done for taking the time to invest in you. 👏",
        "Keep your mind stimulated. Check out what's new on the app below. ✨",
        'Hello wellness warrior! Nice to see you working out the muscle of your mind. 🧠',
        'Five minutes spent looking after your mind is five minutes spent extraordinarily well!',
        "And breathe… We're here to help you relax. 🍃",
        'Welcome back. Always nice to see a friendly face here. 🤗',
        'Looking to learn a little more about… well, you? Check out our bite-sized videos.',
        "Whether you have 2-minutes or 20, there's something here for you.",
    ];

    const index = Math.floor(Math.random() * messages.length);
    return messages[index];
}