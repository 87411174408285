import { generateRgbVar } from '@/utils';

export default function createRgbVarsForThemes(themes) {
  Object.entries(themes).forEach(theme => {
    Object.entries(theme[1]).forEach(colors => {
      const key = colors[0];

      if (typeof colors[1] === 'object') {
        Object.entries(colors[1]).forEach(color => {
          const colorKey = color[0];
          generateRgbVar(`${key}-${colorKey}`, color[1]);
        });
      } else {
        generateRgbVar(key, colors[1].toString());
      }
    });
  });
}
