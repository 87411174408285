import 'firebase/firestore';
import { ApiService } from '../../services/ApiService';

const entityPrefix = 'courses';
export const actions = {
    async fetchAll(context, hardFetch = false) {
        // only fetch courses if they are not already fetched
        if (!context.state.courses.length || hardFetch) {
            const { data } = await ApiService.get(`/${entityPrefix}/with-user-details`);
            context.commit('setCourses', data);
            const customRecommendedCourses = context.rootGetters['OrganizationsModule/getOrganization'].recommendedCourses || [];
            context.commit('setRecommendedCourses', customRecommendedCourses);
            context.commit('setFilteredCoursesByPurpose');
        }
    },
    async fetchOne(context, id) {
        if (!context.getters.areCoursesFetched) {
            await context.dispatch('fetchAll');
        }
        return { data: context.getters.courses.find(course => course.id === id) };
    },
    async getHistoryCourses(context) {
        await context.dispatch('fetchAll');
        const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
        const { data } = await ApiService.get(`/analytics/get-courses-history/${organizationId}`);
        context.commit('setHistoryCoursesIds', data);
    },
    addCourseToUserHistory(context, courseId) {
        const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
        return ApiService.post(`/analytics/courses-history/${organizationId}`, { courseId });
    },
    async clearCoursesHistory(context) {
        const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
        await ApiService.delete(`/analytics/courses-history/${organizationId}`);
        context.commit('setHistoryCoursesIds', []);
    },
    async completeCourse(context, courseId) {
        const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
        await ApiService.post(`/analytics/course-progress/mark-complete/${organizationId}`, { courseId });
        await context.dispatch('fetchAll', true);
    },
    async switchFavoriteState(context, courseId) {
        // Send a request to the API to toggle the favorite state of the course
        await ApiService.post(`/${entityPrefix}/switchFavoriteState`, { courseId });
        // Update the course in the state
        const newCourses = context.state.courses.map(course => {
            if (course.id === courseId) {
                return {
                    ...course,
                    isFavorite: !course.isFavorite,
                };
            }
            return course;
        });
        context.commit('setCourses', newCourses);
        context.commit('switchFavoriteRecommendedCourse', courseId);
        context.commit('switchFavoriteFilteredCoursesByPurpose', courseId);
    },
};
