/**
 * Reset state to its original values on logout.
 * Each module state has property `initialState` which contains the original state of the module.
 *
 * I created this function as a utility function instead of a mutation, because mutations and actions
 * of other modules are inaccessible, unlike their state and getters, with `rootState` and `rootGetters`
 * respectively.
 * @param {object} state the module to reset.
 */
export default function resetState(state) {
  if (state.initialState) {
    Object.assign(state, state.initialState);
  }
}
