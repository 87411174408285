export const searchModule = {
  namespaced: true,
  state: () => ({
    searchKeyword: '',
  }),
  mutations: {
    setSearchKeyword(state, searchKeyword) {
      state.searchKeyword = searchKeyword;
    },
  },
  getters: {
    getSearchKeyword(state) {
      return state.searchKeyword;
    },
  },
};
