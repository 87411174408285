export default function formatMinutes(totalMinutes) {
  // calculate the total hours
  let totalHours = Math.floor(totalMinutes / 60);
  let remainingMinutes = totalMinutes % 60;

  // return it as object, maing it more customizable
  return {
    totalHours,
    remainingMinutes: remainingMinutes.toString().padStart(2, '0'),
  };
}
