import {
    getAuth,
    signInWithEmailAndPassword,
    sendPasswordResetEmail as sendFBpasswordResetEmail,
    signOut,
    updateEmail,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { setUserId, setUserProperties } from 'firebase/analytics';

import { db, analytics } from '../../main';
import router from '../../router';
import { ApiService } from '../../services/ApiService';
import resetState from '@/utils/resetState';
const entityPrefix = 'users';

export const actions = {
    register(_, data) {
        return ApiService.post(`/${entityPrefix}`, data);
    },
    async updateUserData(context, data) {
        await ApiService.patch(`/${entityPrefix}/${data.uid}`, data);
        await context.dispatch('fetchUserFSDocument');
    },
    onAuthStateChanged(context) {
        const auth = getAuth();
        auth.onAuthStateChanged(function(user) {
            if (user) {
                context.commit('setCurrentUser', user);
                setUserId(analytics, user.uid);
            } else {
                context.commit('setCurrentUser', null);
            }
        });
    },
    verifyAuth(context) {
        // returns true if the user is authenticated, false if not
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            context.commit('setCurrentUser', user);
        }
        return !!user;
    },
    signInToFirebaseByEmail(context, credentials) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then(userCredential => {
                    const user = userCredential.user;
                    context.commit('setCurrentUser', user);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async fetchUserFSDocument(context) {
        const currentUser = await context.getters['getCurrentUserFromFirebaseAuth'];
        if (currentUser) {
            const currentUserId = currentUser.uid;
            const userDocRef = doc(db, 'users', currentUserId);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                const userFSDocument = docSnap.data();
                context.commit('setUserFSDocument', userFSDocument);
                setUserProperties(analytics, {
                    organization_id: userFSDocument.organizationId,
                });
                return userFSDocument;
            } else {
                await context.dispatch('logout');
            }
        } else {
            await context.dispatch('logout');
            return null;
        }
    },
    async sendPasswordResetEmail(context, email) {
        const auth = getAuth();
        await sendFBpasswordResetEmail(auth, email);
    },
    async changeEmailAddress(context, newEmail) {
        const auth = getAuth();
        const user = auth.currentUser;
        await updateEmail(user, newEmail);
        await context.dispatch('updateUserData', { uid: user.uid, email: newEmail });
    },

    logout(context) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signOut(auth)
                .then(() => {
                    router.push({ name: 'Login' });
                    // Reset state
                    context.dispatch('resetState');
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    /**
     * It doesn't make an asynchronous call, but it has access to all modules state.
     */
    resetState({ rootState }) {
        for (const module in rootState) {
            resetState(rootState[module]);
        }
    },
};