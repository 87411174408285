import arrayShuffle from '@/utils/arrayShuffle';

export const mutations = {
    setCourseCurrentlyPlayed(state, newVal) {
        state.courseCurrentlyPlayed = newVal;
    },
    setCourses(state, newVal) {
        state.courses = newVal;
    },
    setHistoryCoursesIds(state, newVal) {
        state.historyCoursesIds = newVal;
    },
    setFilteredCoursesByPurpose(state) {
        state.courses.forEach(course => {
            switch (course.purpose) {
                case 'learn':
                    state.filteredCourses.byPurpose.learn[course.id] = course;
                    break;
                case 'meditate':
                    state.filteredCourses.byPurpose.meditate[course.id] = course;
                    break;
                case 'sleep':
                    state.filteredCourses.byPurpose.sleep[course.id] = course;
                    break;
            }
        });
    },
    setRecommendedCourses(state, customRecommendedCourses) {
        // does organization has custom recommended courses?
        if (customRecommendedCourses.length) {
            // if yes, then set them to the state
            state.recommendedCourses = customRecommendedCourses;
            return;
        }
        const recommendedCoursesArr = state.courses.filter(course => course.isRecommended);
        // Shuffle the recommendedCoursesArr
        arrayShuffle(recommendedCoursesArr);
        // Store 4 elements from shuffled array in the state
        state.recommendedCourses = recommendedCoursesArr.slice(0, 4);
    },
    switchFavoriteRecommendedCourse(state, courseId) {
        state.recommendedCourses = state.recommendedCourses.map(course => {
            if (course.id === courseId) {
                return {
                    ...course,
                    isFavorite: !course.isFavorite,
                };
            }
            return course;
        });
    },
    switchFavoriteFilteredCoursesByPurpose(state, courseId) {
        const purposes = state.filteredCourses.byPurpose;

        for (const purpose in purposes) {
            for (const id in purposes[purpose]) {
                const course = purposes[purpose][id];

                if (course.id === courseId) {
                    purposes[purpose][id] = {...course, isFavorite: !course.isFavorite };
                }
            }
        }
    },
};
