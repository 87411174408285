export const state = {
  courseCurrentlyPlayed: null,
  courses: [],
  historyCoursesIds: [],
  recommendedCourses: [],
  filteredCourses: {
    byPurpose: {
      learn: {},
      meditate: {},
      sleep: {},
    },
  },
  initialState: {
    courseCurrentlyPlayed: null,
    courses: [],
    historyCoursesIds: [],
    recommendedCourses: [],
    filteredCourses: {
      byPurpose: {
        learn: {},
        meditate: {},
        sleep: {},
      },
    },
  },
};
