export const getters = {
  courseCurrentlyPlayed(state) {
    return state.courseCurrentlyPlayed;
  },
  courses(state) {
    return state.courses;
  },
  getCourse: state => id => {
    return state.courses.find(course => course.id === id);
  },
  historyCoursesIds(state) {
    return state.historyCoursesIds;
  },
  getCoursesByAuthor: state => id => {
    return state.courses.filter(course => {
      return course.authors.find(uid => uid == id);
    });
  },
  recommendedCourses(state) {
    return state.recommendedCourses;
  },
  unrecommendedCourses(state) {
    return state.courses.filter(course => {
      return !course.isRecommended;
    });
  },
  learnCourses(state) {
    return state.filteredCourses.byPurpose.learn;
  },
  meditateCourses(state) {
    return state.filteredCourses.byPurpose.meditate;
  },
  sleepCourses(state) {
    return state.filteredCourses.byPurpose.sleep;
  },
  newOnTheAppCourses(_, getters) {
    return getters.unrecommendedCourses
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        else if (a.createdAt < b.createdAt) return 1;
        return 0;
      })
      .slice(0, 4);
  },
  historyCourses(_, getters) {
    return getters.courses.filter(course => {
      return getters.historyCoursesIds.includes(course.id);
    });
  },
  areCoursesFetched(_, getters) {
    return getters.courses.length > 0;
  },
  getCoursesByTag: state => pram => {
    return state.courses.filter(course => {
      return course.tags.find(tag => tag == pram);
    });
  },
};
