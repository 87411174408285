import 'firebase/firestore';
import { ApiService } from '../../services/ApiService';

const entityPrefix = 'analytics';
export const actions = {
  async fetchStreak(context) {
    const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
    return ApiService.get(`${entityPrefix}/user-streak/${organizationId}`);
  },
  async fetchProfileStats(context) {
    const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
    return ApiService.get(`${entityPrefix}/user-profile-stats/${organizationId}`);
  },
  async increaseTimeSpent(context) {
    const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
    const courseCurrentlyPlayed = context.rootGetters['CoursesModule/courseCurrentlyPlayed'];
    // if courseCurrentlyPlayed is null only the time spent on app will be increased
    // if the courseCurrentlyPlayed is not null, both timeSpentOnApp and timeSpentOncourses will increase
    return ApiService.post(`${entityPrefix}/time-spent/increment/${organizationId}`, {
      courseId: courseCurrentlyPlayed,
    });
  },
  async saveMaxReachedDuration(context, newMaxReach) {
    const organizationId = context.rootGetters['AuthModule/getUserOrganizationId'];
    const courseCurrentlyPlayed = context.rootGetters['CoursesModule/courseCurrentlyPlayed'];
    if (courseCurrentlyPlayed && organizationId && newMaxReach) {
      console.log('newMaxReach', newMaxReach);
      newMaxReach = Math.round(newMaxReach / 60); // convert to minutes
      return ApiService.post(`${entityPrefix}/course-progress/${organizationId}`, {
        courseId: courseCurrentlyPlayed,
        newMaxReach,
      });
    }
  },
};
