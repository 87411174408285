const userUnprotectedRoutes = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/register/Register.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/forgot-password/ForgotPassword.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import('@/views/auth/password-reset/PasswordReset.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/logout/Logout.vue'),
  },
];

export default userUnprotectedRoutes;