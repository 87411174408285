const ChooseCategory = () => import('@/views/auth/choose-category/ChooseCategory.vue');
const Tutorial = () => import('@/views/tutorial/tutorial/Tutorial.vue');
const CoursesByCategory = () => import('@/views/courses/courses-by-category/CoursesByCategory.vue');
const CoursesByPurpose = () => import('@/views/courses/courses-by-purpose/CoursesByPurpose.vue');
const CoursesByTag = () => import('@/views/courses/courses-by-tag/CoursesByTag.vue');
const CourseDetails = () => import('@/views/courses/course-details/CourseDetails.vue');
const AuthorDetails = () => import('@/views/authors/author-details/AuthorDetails.vue');
const Explore = () => import('@/views/explore/Explore.vue');
const Home = () => import('@/views/home/Home.vue');
const Profile = () => import('@/views/profile/profile/Profile.vue');
const Favourites = () => import('@/views/profile/favourites/Favourites.vue');
const History = () => import('@/views/profile/history/History.vue');
const Settings = () => import('@/views/profile/settings/settings/Settings.vue');
const ChangeName = () => import('@/views/profile/settings/change-name/ChangeName.vue');
const ChangeEmail = () => import('@/views/profile/settings/change-email/ChangeEmail.vue');
const EmailPreferences = () => import('@/views/profile/settings/email-preferences/EmailPreferences.vue');

const userProtectedRoutes = [
  {
    path: '/home',
    name: 'Home',
    meta: {
      showMobileTabBar: true,
    },
    component: Home,
  },
  {
    path: '/explore',
    name: 'Explore',
    meta: {
      showMobileTabBar: true,
    },
    component: Explore,
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      showMobileTabBar: true,
    },
    component: Profile,
  },
  {
    path: '/courses/:courseId',
    name: 'CourseDetails',
    component: CourseDetails,
  },
  {
    path: 'authors/:authorId',
    name: 'AuthorDetails',
    component: AuthorDetails,
  },
  {
    path: '/profile/favourites',
    name: 'Favourites',
    component: Favourites,
  },
  {
    path: '/profile/history',
    name: 'History',
    component: History,
  },
  {
    path: '/profile/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/profile/settings/change-name',
    name: 'ChangeName',
    component: ChangeName,
  },
  {
    path: '/profile/settings/change-email',
    name: 'ChangeEmail',
    component: ChangeEmail,
  },
  {
    path: '/profile/settings/email-preferences',
    name: 'EmailPreferences',
    component: EmailPreferences,
  },
  {
    path: '/courses-by-category/:categoryId',
    name: 'CoursesByCategory',
    component: CoursesByCategory,
  },
  {
    path: '/courses-by-purpose/:purpose',
    name: 'CoursesByPurpose',
    component: CoursesByPurpose,
  },
  {
    path: '/courses-by-tag/:tag',
    name: 'CoursesByTag',
    component: CoursesByTag,
  },
  {
    path: '/choose-category',
    name: 'ChooseCategory',
    component: ChooseCategory,
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial,
  },
];

export default userProtectedRoutes;
