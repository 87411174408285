import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuex from 'vuex';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './configs/firebaseConfig';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, FieldValue } from 'firebase/firestore';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { alertsService } from './services/AlertsService';
import VueYouTubeEmbed from 'vue-youtube-embed';
import NProgress from 'vue-nprogress';
import CourseGridCard from '@/components/courses/course-grid-card/CourseGridCard.vue';
import './registerServiceWorker';
import VTiptap from "@peepi/vuetify-tiptap";

Vue.use(Vuex);
Vue.use(VTiptap);

Vue.config.productionTip = false;
// Firebase initialization
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const FV = FieldValue;
const auth = getAuth();
setPersistence(auth, browserLocalPersistence);
// analytics
export const analytics = getAnalytics(app);

// SW
const sweetAlertsOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, sweetAlertsOptions);

// global variables
Vue.prototype.$alertsService = alertsService;

// axios
Vue.use(VueAxios, axios);

// VueYouTubeEmbed
Vue.use(VueYouTubeEmbed, { global: true, componentId: 'youtube' });

// NProgress
Vue.use(NProgress);
const nprogress = new NProgress();
const nprogressOptions = {
  latencyThreshold: 200,
  router: true,
  http: false,
};
Vue.use(NProgress, nprogressOptions);

// VueYouTubeEmbed
Vue.use(VueYouTubeEmbed, { global: true, componentId: 'youtube' });

// global components
Vue.component('CourseGridCard', CourseGridCard);

new Vue({
  vuetify,
  router,
  store,
  nprogress,
  render: h => h(App),
}).$mount('#app');
