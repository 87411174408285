import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        primaryColor: '#3DDED6',
        secondaryColor: '#23f9c7',
        brandGrey1: '#394448',
        brandGrey2: '#6D7C82',
        offWhite: '#F8F8F8',
        offBlack: '#0D2F4E',
        errorDark: '#AC0000',
        textColor: '#394448',
      },
    },
    options: { customProperties: true },
  },
});
