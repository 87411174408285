const Swal = require('sweetalert2');

export const alertsService = {
  fireSuccessMessage(message) {
    Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ddd',
    });
  },
  confirmAction(question, successCallback) {
    Swal.fire({
      title: question,
      showCancelButton: true,
      confirmButtonText: 'Continue',
      confirmButtonColor: '#d33',
      icon: 'question',
    }).then(result => {
      if (result.isConfirmed) {
        successCallback();
      }
    });
  },
  fireAxiosError(error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      Array.isArray(error.response.data.message)
    ) {
      // Request made and server responded
      let htmlMessage = error.response.data.message
        .map(el => {
          return `<li  style="float:left;color:grey;">-${el}</li>`;
        })
        .join('<br/>');
      Swal.fire({
        title: 'Something Went wrong!',
        html: `<ul>${htmlMessage}</ul>`,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ddd',
        icon: 'warning',
      });
    } else if (error && error.response && error.response.data && error.response.data.message) {
      // Request made and server responded
      Swal.fire({
        title: 'Something Went wrong!',
        text: error.response.data.message,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ddd',
        icon: 'warning',
      });
    } else if (error && error.message) {
      Swal.fire({
        title: 'Something Went wrong!',
        text: error.message,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ddd',
        icon: 'warning',
      });
    } else {
      Swal.fire({
        title: 'Something Went wrong!',
        text: error,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        icon: 'warning',
      });
    }
  },
  fireValidationError(error) {
    Swal.fire({
      title: 'Validation error',
      text: error,
      html: error,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ddd',
      icon: 'warning',
    });
  },
  fireGeneralError(error) {
    Swal.fire({
      title: 'Something went wrong',
      text: error,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ddd',
      icon: 'warning',
    });
  },
};
