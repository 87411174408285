import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import userProtectedRoutes from './userProtectedRoutes';
import userUnprotectedRoutes from './userUnprotectedRoutes';

Vue.use(VueRouter);

const routes = [
  // default takes to explore
  {
    path: '/',
    redirect: '/home',
  },
  // public routes (no authentication needed)
  {
    path: '/',
    component: () => import('@/layouts/UnprotectedRoutesView.vue'),
    children: userUnprotectedRoutes,
  },
  // protected routes (user role level is needed to access)
  {
    path: '/',
    meta: {
      requiresAuthenticatedUser: true,
    },
    component: () => import('@/layouts/protected-routes-view/ProtectedRoutesView.vue'),
    children: userProtectedRoutes,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  //? scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  //? protected routes middlewares
  //user role level check
  if (to.matched.some(record => record.meta.requiresAuthenticatedUser)) {
    //? check if user authenticated
    const authenticatedUser = await store.getters['AuthModule/getCurrentUserFromFirebaseAuth'];
    const userFSDocument = await store.getters['AuthModule/userFSDocument'];

    if (!authenticatedUser) {
      next({
        // User login
        name: 'Login',
      });
    }

    // case of user without a name or favorite categories
    if (
      userFSDocument &&
      to.name !== 'ChooseCategory' &&
      (!userFSDocument.name || userFSDocument.favoriteCategories.length === 0)
    ) {
      store.commit('setErrorSnackbarMessage', 'Please fill in missing info');
      next({
        name: 'ChooseCategory',
      });
    }
  }

  // if no condition was matched, continue to next route
  next();
});

export default router;
