import axios from 'axios';
import { getAuth } from 'firebase/auth';
export const ApiService = {
  instance: axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL + '/api',
  }),

  getCurrentUserFromFirebaseAuth() {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  },
  async setHeaders() {
    // setting the base url to axios
    this.instance.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL + '/api';

    // getting the token from firebase and setting it to this.instance
    const user = await this.getCurrentUserFromFirebaseAuth();
    if (user !== null) {
      const token = await user.getIdToken();
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('no user found,auth token will be null');
    }
  },

  get(resource, slug = '') {
    return this.setHeaders().then(() => {
      return this.instance.get(`${resource}/${slug}`);
    });
  },

  post(resource, data) {
    return this.setHeaders().then(() => {
      return this.instance.post(`${resource}`, data);
    });
  },

  update(resource, slug, data) {
    return this.setHeaders().then(() => {
      return this.instance.put(`${resource}/${slug}`, data);
    });
  },

  put(resource, data) {
    return this.setHeaders().then(() => {
      return this.instance.put(`${resource}`, data);
    });
  },

  patch(resource, data) {
    return this.setHeaders().then(() => {
      return this.instance.patch(`${resource}`, data);
    });
  },
  delete(resource) {
    return this.setHeaders().then(() => {
      return this.instance.delete(`${resource}`);
    });
  },
};
