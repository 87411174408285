import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import translateFirebaseError from './translateFirebaseError';

/**
 * Formats errors coming from either NestJS, FireBase, or just any normal error (variable not defined...)
 * @param {object} error Error object to extract the message from.
 * @returns {string} error messag out of the error object.
 */
export default function getErrorMessage(error) {
  // If error is from Axios, since we work with the back-end and other APIs with it.
  if (error instanceof AxiosError) {
    return error.response.data.message;
    // Else, if the error is from Firebase
  } else if (error instanceof FirebaseError) {
    return translateFirebaseError(error);
    // Else, it is a normal error, since they have the same structure.
  } else {
    return error.message;
  }
}
